import { fileChecksum } from 'utils/checksum'
import axios from 'axios'

const getUrl = async function(selectedFile) {
  const checksum = await fileChecksum(selectedFile)

  const file = {
    "filename": selectedFile.name,
    "byte_size": selectedFile.size,
    "checksum": checksum,
    "content_type": selectedFile.type,
  }

  const url = await axios.post(`/presigned_url`, { file } )

  const response = {
    url: url.data.direct_upload.url,
    headers: url.data.direct_upload.headers,
    blob_signed_id: url.data.blob_signed_id
  }
  
  return response
}

const putFile = async function(selectedFile)  {
  const url = await getUrl(selectedFile)

  const options = {
    method: 'PUT',
    headers: url.headers,
    data: selectedFile,
    url: url.url,
  };

  await axios(options)
  return url.blob_signed_id
}

export const uploadFile = async(selectedFile) => {
  const url = await putFile(selectedFile)
  return url
}
  