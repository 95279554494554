import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import SelectSingle from './selects/SelectSingle.js'
import SelectFiscalYear from './selects/SelectFiscalYear.js'
import SelectMultiple from './selects/SelectMultiple.js'
import SelectGroupedMultiple from './selects/SelectGroupedMultiple.js'
import CollectionForm from './CollectionForm.js'
import { uploadFile } from 'utils/directUpload.js'
// to use local storage for files, comment out line above, and uncomment the line below
//import { uploadFile } from 'utils/localUpload.js'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { BiReset, BiPlus } from "react-icons/bi";
import InputGroup from 'react-bootstrap/InputGroup'
import Modal from 'react-bootstrap/Modal'


class ResourceForm extends React.Component {
  constructor(props) {

    super(props);

    const fyDate = this.props.resource.fiscal_year != null ? new Date(this.props.resource.fiscal_year) : new Date()

    this.state = {
      id: this.props.resource.id || "",
      title: this.props.resource.name || "",
      implementingMechanismID: this.props.resource.implementing_mechanism_id,
      tagIDs: this.props.resource.tags.map(i => (i.id)) || [],
      collectionIDs: this.props.resource.collections.map(i => (i.id)) || [],
      locationIDs: this.props.resource.locations.map(i => (i.id)) || [],
      file: this.props.resource.file || null,
      url: this.props.resource.url || "",
      description: this.props.resource.description || "",
      fiscalYear: fyDate,
      alertMessage: "",
      invalidURL: false,
      blobIdOrFile: this.props.blobSignedId || "",
      fileName: this.props.fileName || "",
      showAddCollectionModal: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAlertMessage = this.handleAlertMessage.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleURLBlur = this.handleURLBlur.bind(this);
    this.validURL = this.validURL.bind(this);
    this.handleFYDateChange = this.handleFYDateChange.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleCollectionsChange = this.handleCollectionsChange.bind(this);
    this.handleLocationsChange = this.handleLocationsChange.bind(this);
    this.handleImplementingMechanismChange = this.handleImplementingMechanismChange.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleFileClear = this.handleFileClear.bind(this);
    this.showAddCollectionModal = this.showAddCollectionModal.bind(this);
    this.closeAddCollectionModal = this.closeAddCollectionModal.bind(this);
  }

  // uploads the file only if it does not already exist, and appends it to request data accordingly
  createResourceData = async function()  {
    
    let formData = new FormData()
    formData.append('resource[name]', this.state.title)
    formData.append('resource[implementing_mechanism_id]',  this.state.implementingMechanismID)
    this.state.tagIDs.forEach(tagId => {
      formData.append('resource[tag_ids][]',  tagId)
    })
    this.state.collectionIDs.forEach(collectionId => {
      formData.append('resource[collection_ids][]',  collectionId)
    })
    this.state.locationIDs.forEach(locationId => {
      formData.append('resource[location_ids][]',  locationId)
    })
    formData.append('resource[url]',  this.state.url)
    formData.append('resource[description]',  this.state.description)
    formData.append('resource[fiscal_year]',  this.state.fiscalYear)

    if (!this.state.url && this.state.file) {
      if (!this.state.blobIdOrFile) {
        const blobIdOrFile = await uploadFile(this.state.file)
        this.setState({ blobIdOrFile: blobIdOrFile });
      }
      formData.append('resource[file]',  this.state.blobIdOrFile)
    } 
      
    return formData

  }

  handleSubmit(e) {
    e.preventDefault();
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;

    if (this.state.title && this.state.implementingMechanismID && this.state.locationIDs.length > 0 && this.state.tagIDs.length > 0 && (this.validURL(this.state.url) || this.state.fileName)) {
      
      if (this.state.id) {
        this.createResourceData().then((resource) => {
          axios.put(`/resources/${this.state.id}`, resource )
          .then(res => {
            return window.location.href = `/resources/${this.state.id}`
          })
          .catch(error => {
            console.log(error)
            this.setState({ alertMessage: I18n.t("resource.save_fail") });
          })
        })
      } else {
        this.createResourceData().then((resource) => {
          axios.post(`/resources`,  resource )
          .then(res => {
            return window.location.href = '/search'
          })
          .catch(error => {
            console.log(error);
            this.setState({ alertMessage: I18n.t("resource.save_fail") });
          })
        })
      }
    } else {
      this.setState({ alertMessage: I18n.t('errors.required_fields') });
    }
  }

  handleAlertMessage() {
    if (this.state.alertMessage){
      return (
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          {this.state.alertMessage}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    } else {
      return ""
    }
  };

  handleFileSelect(e) {
    this.setState({blobIdOrFile: "" })
    this.setState({url: "" })
    this.setState({file: e.target.files[0]})
    this.setState({fileName: e.target.files[0].name})
  }

  handleFileClear(e) {
    this.setState({blobIdOrFile: "" })
    this.setState({file: null})
    this.setState({fileName: ""})
  }

  handleInputChange(e) {

    const target = e.target
    const value = target.value
    const name = target.name
    if (Array.isArray(this.state[name])) {
      let choices = this.state[name]
      const i = choices.indexOf(value)
      if (i > -1) {
        choices.splice(i, 1)
      } else {
        choices.push(value)
        this.setState({
          [name]: choices
        })
      }
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleImplementingMechanismChange(e) {
    this.setState({
      implementingMechanismID: e.value
    })
  }

  handleTagsChange(e) {
    const tagIDs = e != null ? e.map(t => t.value) : []
    this.setState({
      tagIDs: tagIDs
    })
  }

  handleCollectionsChange(e) {
    const collectionIDs = e != null ? e.map(t => t.value) : []
    this.setState({
      collectionIDs: collectionIDs
    })
  }

  handleLocationsChange(e) {
    const locationIDs = e != null ? e.map(t => t.value) : []
    this.setState({
      locationIDs: locationIDs
    })
  }


  handleURLBlur(e) {
    if (!this.validURL(e.target.value) && this.state.file == "") {
      this.setState({
        alertMessage: "Please enter a valid file URL or upload a file",
        urlMsg: "  --Please enter a valid file URL or upload a file."
      });
    } else if (!this.validURL(e.target.value) && this.state.file) {
      this.setState({ url: "" })
    } else {
      this.setState({ urlMsg: "" })
    }
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str)
  }

  handleFYDateChange(e) {
    let d = this.state.fiscalYear
    if (e.target.name=="fyMonth") {
      d.setMonth(e.target.value)
    } else {
      d.setYear(e.target.value)
    }

    this.setState({fiscalYear: d})
  }

  showAddCollectionModal() {
    this.setState({showAddCollectionModal: true})
  }

  closeAddCollectionModal(c) {
    let selected = this.state.collectionIDs.slice();
    if (c) { selected.push(c) };
    this.setState({
      showAddCollectionModal: false,
      collectionIDs: selected
    })
  }

  render() {
      return (
        <Form>
          <Modal
            show={this.state.showAddCollectionModal}
            onHide={this.closeAddCollectionModal}
            keyboard={true}
            size={'lg'}>
            <Modal.Header closeButton>
              <Modal.Title>{I18n.t('collection.add_a_collection')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CollectionForm
                authenticityToken={this.props.authenticityToken}
                isModal={true}
                closeModal={this.closeAddCollectionModal} />
            </Modal.Body>
          </Modal>

          <Row>
            <Col>
              {this.handleAlertMessage()}
            </Col>
          </Row>

          <Form.Group controlId="resourceForm.name">
            <Form.Label>{I18n.t('activerecord.attributes.resource.name')}<span className="text-danger">*</span></Form.Label>
            <Form.Control 
              name="title"
              type="text"
              value={this.state.title}
              onChange={this.handleInputChange} />
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col} controlId="resourceForm.file">
              <Form.Label>{I18n.t('resource.upload_file')}</Form.Label>
              <InputGroup >
                <Form.File id="formcheck-api-custom" 
                  disabled={this.state.url}
                  custom>
                  <Form.File.Input 
                    id="upfield" 
                    onChange={this.handleFileSelect}
                    disabled={this.state.url} />
                  <Form.File.Label>
                    {this.state.fileName || I18n.t('resource.upload_file')} 
                  </Form.File.Label>
                </Form.File>
                <InputGroup.Append>
                  <Button variant="outline-secondary" onClick={this.handleFileClear}><BiReset /></Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="resourceForm.url">
              <Form.Label>{I18n.t('resource.file_url')}<span className="text-danger">{this.state.urlMsg}</span></Form.Label>
              <Form.Control 
                name="url"
                type="text"
                value={this.state.url}
                onChange={this.handleInputChange}
                onBlur={this.handleURLBlur}
                disabled={this.state.fileName} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="resourceForm.implementing_mechanism">
              <Form.Label>{I18n.t('activerecord.attributes.resource.implementing_mechanism')}<span className="text-danger">*</span></Form.Label>
              <SelectSingle
                selected={this.state.implementingMechanismID}
                onValueChange={this.handleImplementingMechanismChange}
                url='/implementing_mechanisms.json'
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} controlId="resourceForm.collections">
              <Form.Label>{I18n.t('activerecord.attributes.resource.collections')} &nbsp; <Button variant="outline-primary" size={"sm"} onClick={this.showAddCollectionModal} className="add-collection-btn"><BiPlus /></Button></Form.Label>
              <SelectMultiple
                selected={this.state.collectionIDs}
                onChange={this.handleCollectionsChange}
                url='/collections.json'
                placeholder={I18n.t('selects.multiple_optional')}  />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="resourceForm.tags">
              <Form.Label>{I18n.t('activerecord.attributes.resource.tags')}<span className="text-danger">*</span></Form.Label>
              <SelectGroupedMultiple
                selected={this.state.tagIDs}
                onChange={this.handleTagsChange} 
                url='/grouped_tags'
                placeholder={I18n.t('selects.multiple_required')}
                groupedName='tags' />
            </Form.Group>

            <Form.Group as={Col} controlId="resourceForm.locations">
              <Form.Label>{I18n.t('activerecord.attributes.resource.locations')}<span className="text-danger">*</span></Form.Label>
              <SelectMultiple
                  selected={this.state.locationIDs}
                  onChange={this.handleLocationsChange} 
                  url='/locations.json?hierarchy=true'
                  placeholder={I18n.t('selects.multiple_required')}  />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="resourceForm.description">
              <Form.Label>{I18n.t('activerecord.attributes.resource.description')}</Form.Label>
              <Form.Control 
                as="textarea" 
                name="description"
                value={this.state.description}
                onChange={this.handleInputChange}
                rows="1" />
            </Form.Group>

            <Form.Group as={Col} md={4} controlId="resourceForm.fy">
              <Form.Label>{I18n.t('activerecord.attributes.resource.fiscal_year_start_date')}</Form.Label>
              <SelectFiscalYear
                fyDate={this.state.fiscalYear}
                onChange={this.handleFYDateChange} />
            </Form.Group>
          </Form.Row>

          <Form.Group  controlId="resourceForm.buttons">
            <Button variant="outline-primary" onClick={this.handleSubmit}>{this.state.id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
            <Button variant="outline-secondary" href={"/search"}>{I18n.t("navigation.cancel")}</Button>
          </Form.Group>
        </Form>
      );
  };
}

ResourceForm.propTypes = {
  resource: PropTypes.object,
  authenticityToken: PropTypes.string,
  blobSignedId: PropTypes.string,
  fileName: PropTypes.string,
};

export default ResourceForm
