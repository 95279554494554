import React from 'react';
import PropTypes from "prop-types"
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BiTrash } from 'react-icons/bi';
import { FaStar } from "react-icons/fa";
import I18n from 'i18n-js/locales.js'

class ListItemSelect extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      placeholder: I18n.t("search.name_placeholder"),
      enableLoadItem: true,
      newItemName: "",
    }

    this.handleNameInput = this.handleNameInput.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.toggleLoadItem = this.toggleLoadItem.bind(this);
  }

  handleNameInput(e) {
    this.setState({
      newItemName: e.target.value
    })
  }

  selectItem(e) {
    if (this.state.enableLoadItem) {
      const item = this.props.listItems.find(i => i.uuid === e);
      this.props.selectItem(item)
    }
  }

  deleteItem(e) {
    this.props.deleteItem(e.currentTarget.value);
  }

  toggleLoadItem() {
    const enableLoadItem = !this.state.enableLoadItem
    this.setState({
      enableLoadItem: enableLoadItem,
    })
  }

  renderSaveForm() {
    return (this.props.showSaveForm ?
      <InputGroup className="px-3 pb-3">
        <FormControl
          id="new-list-item-name-input"
          value={this.state.newItemName}
          placeholder={this.state.placeholder}
          aria-label="Name of saved list item"
          onChange={this.handleNameInput} />
        <InputGroup.Append>
          <Button onClick={this.props.saveItem}><FaStar /></Button>
        </InputGroup.Append>
      </InputGroup>
    : "")
  }

  renderSavedItems() {
    return this.props.listItems.length > 0 ? (
      this.props.listItems.map(s =>
        <Dropdown.Item key={s.uuid} eventKey={s.uuid} className="pl-1 saved-list-item-option" onSelect={this.selectItem}>
          <Button
            value={s.uuid}
            onClick={this.deleteItem}
            onMouseEnter={this.toggleLoadItem}
            onMouseLeave={this.toggleLoadItem}
            variant="link"
            className="btn-delete-saved-list-item">
            <BiTrash />
          </Button>
          {s.name}
        </Dropdown.Item>
      )
    ) : (<div className="p-3">{I18n.t('search.none_saved')}</div>)
  }
        
  render() {
    return (
      <div>
        {this.renderSaveForm()}
        {this.renderSavedItems()}
      </div>
    )
  }
}

ListItemSelect.propTypes = {
  showSaveForm: PropTypes.bool,
  listItems: PropTypes.array,
  saveItem: PropTypes.func,
  deleteItem: PropTypes.func,
  selectItem: PropTypes.func
}

export default ListItemSelect