import React, { useEffect, useState }  from "react"
import ListGroup from 'react-bootstrap/ListGroup'
import axios from 'axios'

export default function ResourceList(props) {
  const [values, setValues] = useState([])

  const getValues = async () => {
    const res = await axios.get(`/search/${props.target}?tag_ids=${props.tagIds.join()}&location_ids=${props.locationIds.join()}&search_terms=${props.searchTerms}&implementing_mechanism_id=${props.implementingMechanismId}`)
    setValues(res.data)
  }

  useEffect(() => {
    getValues()
  }, [props.searchTerms, props.tagIds, props.locationIds, props.implementingMechanismId])

  return (
    <ListGroup variant="flush" className="border-light">
      { values.map(v =>  <ListGroup.Item key={v.id}><strong><a href={"/" + props.target + "/" + v.id + "?tag_ids=" + props.tagIds.join() + "&location_ids=" + props.locationIds.join() + "&search_terms=" + props.searchTerms + "&implementing_mechanism_id=" + props.implementingMechanismId + "&set_search=true"}>{v.name}</a></strong><br/><small>{v.description}</small></ListGroup.Item>)}
    </ListGroup>
  )
}
