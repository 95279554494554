import React, { useEffect, useState }  from "react";
import Badge from 'react-bootstrap/Badge'
import axios from 'axios'

export default function TagSelectList(props) {
  const [values, setValues] = useState({})

  const getValues = async () => {
    const res = await axios.get(`/search/tags?tag_ids=${props.tagIds.join()}&location_ids=${props.locationIds.join()}&search_terms=${props.searchTerms}&implementing_mechanism_id=${props.implementingMechanismId}`)
    setValues(res.data)
  }

  useEffect(() => {
    getValues()
  }, [props.searchTerms, props.tagIds, props.locationIds, props.implementingMechanismId])

  return (
    <>
      { Object.keys(values).map(key =>
        <small key={key}>
          <strong>{key}</strong><br/>
          { values[key]["selected"].map(v =>  <Badge style={{cursor: "pointer"}} variant="info" key={v.id} id={v.id} onClick={props.onTagRemove}>{v.name}</Badge>)}
          <ul className="list-unstyled" key={key}>
            { values[key]["available"].map(v =>  <li key={v.id} value={v.id} onClick={props.onTagAdd}  style={{cursor: "pointer"}}>{v.name} <Badge variant="light">{v.resource_count}</Badge></li>)}
          </ul>
        </small>
        )
      }
    </>
  );
}
