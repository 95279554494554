import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import SelectMultiple from './selects/SelectMultiple.js'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

class CollectionForm extends React.Component {
  static defaultProps = {
    collection: {
      id: "",
      title: "",
      description: "",
      resources: [],
    },
    isModal: false,
    closeModal: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.collection.id || "",
      title: this.props.collection.name || "",
      description: this.props.collection.description || "",
      resourceIDs: this.props.collection.resources.map(i => (i.id)) || [],
      alertMessage: "",
    }

    this.handleCollectionSubmit = this.handleCollectionSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleResourcesChange = this.handleResourcesChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(e) {
    this.props.closeModal(e);
  }

  handleCollectionSubmit(e) {
    e.preventDefault();
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;

    let formData = new FormData()
    formData.append('collection[name]', this.state.title)
    formData.append('collection[description]', this.state.description)
    this.state.resourceIDs.forEach(resourceId => {
      formData.append('collection[resource_ids][]', resourceId)
    })

    if (this.state.title) {
      if (this.state.id) {
        axios.put(`/collections/${this.state.id}`, formData)
        .then(res => {
          return window.location.href = `/collections/${this.state.id}`
        })
        .catch(error => {
          console.log(error);
          this.setState({ alertMessage: I18n.t('collection.save_fail') });
        })
      } else {
        axios.post(`/collections`, formData)
        .then(res => {
          this.handleClose(res.data.id);
          if (!this.props.isModal) {
            return window.location.href = '/search'
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({ alertMessage: I18n.t('collection.save_fail') });
        })
      }
    } else {
      this.setState({ alertMessage: I18n.t('errors.required_fields') });
    }
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
        [name]: value
    });
  }
  
  handleAlertMessage() {
    if (this.state.alertMessage){
      return (
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          {this.state.alertMessage}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    } else {
      return ""
    }
  }

  handleResourcesChange(e) {
    const resourceIDs = e != null ? e.map(t => t.value) : []
    this.setState({
      resourceIDs: resourceIDs
    })
  }

  render() {
    return (
      <Form>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Form.Group>
          <Form.Label>{I18n.t('activerecord.attributes.collection.title')}<span className="text-danger">*</span></Form.Label>
          <Form.Control
            name="title"
            type="text"
            value={this.state.title}
            onChange={this.handleInputChange} />
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>{I18n.t('activerecord.attributes.collection.description')}</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={this.state.description}
              onChange={this.handleInputChange}
              rows="2" />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>{I18n.t('activerecord.attributes.collection.resources')}</Form.Label>
            <SelectMultiple
              selected={this.state.resourceIDs}
              onChange={this.handleResourcesChange}
              url='/resources.json'
              placeholder={I18n.t('selects.multiple_optional')}  />
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Button variant="outline-primary" onClick={this.handleCollectionSubmit}>{this.state.id ? I18n.t('navigation.update') : I18n.t('navigation.add')}</Button> &nbsp;
          <Button variant="outline-secondary" href={this.props.isModal ? "#" : "/search"} onClick={this.handleClose}>{I18n.t('navigation.cancel')}</Button>
        </Form.Group>
      </Form>
    );
  }
}

CollectionForm.propTypes = {
  collection: PropTypes.object,
  authenticityToken: PropTypes.string,
  isModal: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default CollectionForm