import React, { useEffect, useState }  from "react"
import axios from 'axios'
import Select from 'react-select'

export default function SelectSingle(props) {
  const [options, setOptions] = useState([])

  const loadOptions = async () => {
    const res = await axios.get(props.url)
    setOptions(res.data.map(i => ({value: i.id, label: i.name})))
  }

  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <Select 
      clearValue={true}
      onChange={props.onValueChange} 
      value={options.find(obj => obj.value === props.selected)}
      options={options}
      placeholder={props.placeholder} />
  )
}