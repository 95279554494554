import React, { useEffect, useState }  from "react";
import Badge from 'react-bootstrap/Badge'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'

export default function LocationSelectList(props) {
  const [values, setValues] = useState({"selected":[], "available": []})

  const getValues = async () => {
    const res = await axios.get(`/search/locations?tag_ids=${props.tagIds.join()}&location_ids=${props.locationIds.join()}&search_terms=${props.searchTerms}&implementing_mechanism_id=${props.implementingMechanismId}`)
    setValues(res.data)
  }

  useEffect(() => {
    getValues()
  }, [props.searchTerms, props.tagIds, props.locationIds, props.implementingMechanismId])

  return (
    <small>
      <strong>{I18n.t("headers.location.list")}</strong><br/>
        { values["selected"].map(v =>  <Badge style={{cursor: "pointer"}} variant="info" key={v.id} id={v.id} onClick={props.onLocationRemove}>{v.name}</Badge>)}
      <ul className="list-unstyled">
        { values["available"].map(v =>  <li key={v.id} value={v.id} onClick={props.onLocationAdd}  style={{cursor: "pointer"}}>{v.name} <Badge variant="light">{v.resource_count}</Badge></li>)}
      </ul>
    </small>
  );
}
