import React, { Component }  from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import ResourceList from './ResourceList'
import SearchBar from './SearchBar'
import TagSelectList from './TagSelectList'
import LocationSelectList from './LocationSelectList'
import SelectSingle from './selects/SelectSingle.js'
import ListItemSelect from './ListItemSelect.js'
import I18n from 'i18n-js/locales.js'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

class SearchPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tagIds: this.props.tagIds || [],
      locationIds: this.props.locationIds || [],
      searchTerms: this.props.searchTerms || "",
      implementingMechanismId: this.props.implementingMechanismId || "",
      showSavedSearches: false,
      savedSearches: [],
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.saveSearch = this.saveSearch.bind(this);
    this.loadSearch = this.loadSearch.bind(this);
    this.deleteSearch = this.deleteSearch.bind(this);
    this.rewriteSearches = this.rewriteSearches.bind(this);
    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.addLocation = this.addLocation.bind(this);
    this.removeLocation = this.removeLocation.bind(this);
    this.handleMechanism = this.handleMechanism.bind(this);
    this.handleSavedSearches = this.handleSavedSearches.bind(this);
    this.loadSavedSearches = this.loadSavedSearches.bind(this);
    this.hideSavedSearches = this.hideSavedSearches.bind(this);
    this.showSaveForm = this.showSaveForm.bind(this);
  };

  componentDidMount() {
    this.loadSavedSearches();
  }

  handleSearch() {
    this.setState({
      searchTerms: document.getElementById('searchbar').value
    }); 
  }

  handleMechanism(e) {
    this.setState({
      implementingMechanismId: e.value
    })
  }

  resetSearch() {
    this.setState({
      tagIds: [],
      locationIds: [],
      searchTerms: "",
      implementingMechanismId: ""
    }); 

    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    axios.put(`/search/reset`, {})
    .then(res => {
      console.log(res)
    })
    .catch(error => {
      console.log(error.response.data);
    })
  }

  addTag(e) {
    const value = e.target.value;
    let newArray = this.state.tagIds.slice();
    newArray.push(value)
    
    this.setState({
      tagIds: newArray
    });
  }

  removeTag(e) {
    const value = Number(e.target.id);
    let newArray = this.state.tagIds.slice();
    newArray = newArray.filter(function(item) {
      return item !== value && item != null && item != ""
    })

    this.setState({
      tagIds: newArray
    });
  }

  addLocation(e) {
    const value = e.target.value;
    let newArray = this.state.locationIds.slice();
    newArray.push(value)
    
    this.setState({
      locationIds: newArray
    });
  }

  removeLocation(e) {
    const value = Number(e.target.id);
    let newArray = this.state.locationIds.slice();
    newArray = newArray.filter(function(item) {
      return item !== value && item != null && item != ""
    })

    this.setState({
      locationIds: newArray
    });
  }

  handleSavedSearches() {
    const show = this.state.showSavedSearches;
    this.setState({
      showSavedSearches: !show
    })
  }

  loadSavedSearches() {
    return axios.get(`/searches`)
    .then(res => {
      const searches = res.data;
      this.setState({
        savedSearches: searches,
      })
    })
    .catch(error => {
      console.log(error);
    })
  }

  saveSearch() {
    const newSearch = {
      name: document.getElementById('new-list-item-name-input').value,
      tag_ids: this.state.tagIds,
      location_ids: this.state.locationIds,
      search_terms: this.state.searchTerms,
      implementing_mechanism_id: this.state.implementingMechanismId,
      uuid: uuidv4()
    }
    let searches = this.state.savedSearches.slice();
    searches.push(newSearch);

    this.setState({
      savedSearches: searches
    }, this.rewriteSearches);
  }

  deleteSearch(search) {
    const searches = this.state.savedSearches.filter(s => s.uuid !== search).slice();
    this.setState({
      savedSearches: searches
    }, this.rewriteSearches);
  }

  loadSearch(s) {
    this.setState({
      showSavedSearches: false,
      tagIds: s.tag_ids,
      locationIds: s.location_ids,
      searchTerms: s.search_terms,
      implementingMechanismId: s.implementing_mechanism_id
    })
    document.getElementById('searchbar').value = s.search_terms;
  }

  rewriteSearches() {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    const postBody = {
      saved_searches: this.state.savedSearches
    }
    return axios.post(`/searches/all`, postBody)
  }

  hideSavedSearches() {
    this.setState({
      showSavedSearches: false
    })
  }

  showSaveForm() {
    return this.state.implementingMechanismId.length > 0
    || this.state.locationIds.length > 0
    || this.state.tagIds.length > 0
    || this.state.searchTerms.length > 0 ? true : false;
  }

  renderSavedSearches() {
    return (
      <Modal
        show={this.state.showSavedSearches}
        onHide={this.hideSavedSearches}
        keyboard={true}
        backdrop={false}
        className="saved-searches-dropdown">
        <Modal.Header className="modal-header-no-border" closeButton>
          <Modal.Title>{I18n.t('search.saved_searches_modal_title')}</Modal.Title>
        </Modal.Header>
        <ListItemSelect
          showSaveForm={this.showSaveForm()}
          listItems={this.state.savedSearches}
          saveItem={this.saveSearch}
          deleteItem={this.deleteSearch}
          selectItem={this.loadSearch} />
      </Modal>
    )
  }

  render() {
    return (
      <Card body>
        <Row>
          <Col>
            <SearchBar
                searchValue={this.state.searchTerms}
                handleChange={this.handleSearch}
                handleReset={this.resetSearch}
                handleSavedSearches={this.handleSavedSearches}
              /><br/>
            {this.renderSavedSearches()}
          </Col>
        </Row>
        <Row>
          <Col md={3} className="bg-light border-light shadow-sm">
            <Row>
              <Col><br />
                <small>
                  <Form.Group controlId="mechanism">
                    <Form.Label><strong>{I18n.t('activerecord.attributes.resource.implementing_mechanism')}</strong></Form.Label>
                    <SelectSingle
                      selected={this.state.implementingMechanismID}
                      onValueChange={this.handleMechanism}
                      url='/implementing_mechanisms.json'
                      placeholder={I18n.t('selects.all')} />
                  </Form.Group>
                </small>
              </Col>
            </Row>
            <Row>
              <Col>
                <TagSelectList
                  tagIds={this.state.tagIds}
                  locationIds={this.state.locationIds}
                  searchTerms={this.state.searchTerms}
                  implementingMechanismId={this.state.implementingMechanismId}
                  onTagAdd={this.addTag}
                  onTagRemove={this.removeTag}   />
              </Col>
            </Row>
            <Row>
              <Col>
                <LocationSelectList
                  tagIds={this.state.tagIds}
                  locationIds={this.state.locationIds}
                  searchTerms={this.state.searchTerms}
                  implementingMechanismId={this.state.implementingMechanismId}
                  onLocationAdd={this.addLocation}
                  onLocationRemove={this.removeLocation}  />
              </Col>
            </Row>
          </Col>
          <Col md={9} className="pl-3">
            <Tabs defaultActiveKey="resources" id="listTabs" className="shadow-sm">
              <Tab eventKey="resources" title={I18n.t("headers.resource.list")} className="pt-3">
                <ResourceList
                  tagIds={this.state.tagIds}
                  locationIds={this.state.locationIds}
                  searchTerms={this.state.searchTerms} 
                  implementingMechanismId={this.state.implementingMechanismId}
                  target="resources" />
              </Tab>
              <Tab eventKey="collections" title={I18n.t("headers.collection.list")} className="pt-3">
                <ResourceList
                  tagIds={this.state.tagIds}
                  locationIds={this.state.locationIds}
                  searchTerms={this.state.searchTerms} 
                  implementingMechanismId={this.state.implementingMechanismId}
                  target="collections" />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Card>
    );
  };
}

SearchPage.propTypes = {
  tagIds: PropTypes.array,
  locationIds: PropTypes.array,
  searchTerms: PropTypes.string,
  implementingMechanismId: PropTypes.string,
  authenticityToken: PropTypes.string,
};
export default SearchPage
